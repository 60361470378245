import { useState } from "react";
import { Alert, Button } from "react-bootstrap";

const AlertDismissible = ({
  title = "",
  content = "",
  variant = "primary",
  icon = "fa-solid fa-info-circle",
  className = "",
  showModal = false,
}) => {
  const [show, setShow] = useState(showModal);

  if (show) {
    return (
      <Alert
        variant={`${variant} text-start ${className}`}
        onClose={() => setShow(false)}
        dismissible
      >
        <Alert.Heading>{title}</Alert.Heading>
        <>{content}</>
      </Alert>
    );
  }
  return (
    <Button
      variant={`outline-${variant}`}
      onClick={() => setShow(true)}
      size="sm"
    >
      <i className={icon} />
    </Button>
  );
};

export default AlertDismissible;
